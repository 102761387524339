import { apiHeaders } from "../utils";
import { vanillaBasketStore } from "../vanillaBasketStore";
import slug from "slug";

class AddToCartButton extends HTMLButtonElement {
	connectedCallback() {
		this.addEventListener("click", _ => {
			this.addToCart();
		});
		this.updateBasket = vanillaBasketStore.getState().updateBasket;
		this.openBasket = vanillaBasketStore.getState().openDropdown;
	}

	buildRef() {
		const productId = this.getAttribute("product-id");
		let ref = `${productId}`;
		if (this.getAttribute("variants")) {
			const variants = JSON.parse(this.getAttribute("variants"));
			Object.entries(variants).forEach(([key, value]) => {
				ref += `-${slug(key)}-${slug(value)}`;
			});
		}
		return ref;
	}

	addToCart() {
		const form = this.closest("form");
		const invalid = form.querySelector(":invalid");
		if (invalid) {
			invalid.focus();
			return;
		}

		const body = {
			ref: this.buildRef(),
			product_type: "store.Product",
			product_id: this.getAttribute("product-id"),
			quantity: parseInt(this.getAttribute("quantity")),
			extra: {}
		};

		if (this.getAttribute("variants")) {
			body["extra"] = JSON.parse(this.getAttribute("variants"));
		}
		fetch(this.getAttribute("endpoint"), {
			method: "POST",
			headers: apiHeaders(),
			body: JSON.stringify(body)
		})
			.then(response => {
				if (response.ok === true) {
					this.updateBasket();
					this.openBasket();
				} else {
					return response.json().then(json => {
						if (json.non_field_errors) {
							alert(json.non_field_errors[0]);
						}
					});
				}
			})
			.catch(error => {
				console.error(error);
			});
	}
}

window.customElements.define("add-to-cart-button", AddToCartButton, {
	extends: "button"
});
